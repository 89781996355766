import axios from 'axios'

const getVisitorLocation = async () => {
  try {
    const response = await axios.get(
      `http://api.ipstack.com/check?access_key=${process.env.IPSTACK_API_KEY}`,
    )
    const { country_code } = response.data
    return country_code
  } catch (error) {
    console.error(error)
  }
}

export default getVisitorLocation
