import { graphql, navigate } from 'gatsby'
import defineHtmlAst from 'helpers/defineHtmlAst'
import getVisitorLocation from 'helpers/getVisitorLocation'
import { CountryCodes } from 'interfaces/MainRoutesProps'
import { ISalesMasteryPageProps } from 'interfaces/PageInterfaces'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {
  AboutCourseSection,
  BannerSection,
  CourseNavigation,
  ModulesSection,
  // StartCourseSection,
  WhatPeopleSaySection,
  WhoThisIsForSection,
  ScheduleSection,
  MeetInstructorSection,
  FAQSection,
  ConnectSection,
} from 'sections/SalesMastery'

const SalesMastery = ({ data }: ISalesMasteryPageProps) => {
  useEffect(() => {
    getVisitorLocation().then(
      (countryCode: CountryCodes) => {
        if (countryCode === CountryCodes.TR) {
          navigate('/tr/SalesMastery')
        }
      },
    )
  }, [data])

  const {
    bannerText,
    navItems,
    courseOverview,
    whoThisIsFor,
    scheduleTexts,
    meetYourInstructor,
    faq,
    faqTitle,
    whoWeHelpedTestimonials,
    modulesData,
    modulesTitle,
  } = data

  const navTabs = defineHtmlAst(navItems.items)

  const aboutCourseArgs = {
    aboutTitle: courseOverview.aboutTitle,
    aboutDescription:
      courseOverview.aboutDescription.description,
    whatIsIncludedTitle: courseOverview.whatIsIncludedTitle,
    whatIsIncludedList: defineHtmlAst(
      courseOverview.whatIsIncludedList,
    ),
    whatYouWillLearn: courseOverview.whatYouWillLearnTitle,
    whatYouWillLearnList: defineHtmlAst(
      courseOverview.whatYouWillLearnList,
    ),
  }
  const whoThisIsForArgs = {
    title: whoThisIsFor.title,
    articles: defineHtmlAst(whoThisIsFor.list),
  }

  const meetYourInstructorArgs = {
    title: meetYourInstructor.title,
    description: meetYourInstructor.description.description,
  }

  const modules = modulesData.nodes.sort(
    (a, b) => a.index - b.index,
  )

  return (
    <>
      <Helmet title="Sales Mastery" defer={false}>
        <meta
          name="title"
          content="Suada Turkey, Master Satışçı Eğitimi"
        />
        <meta
          name="description"
          content="Master Satışçı Eğitimi"
        />
      </Helmet>
      <BannerSection {...bannerText} />
      {/* <StartCourseSection /> */}
      <CourseNavigation tabs={navTabs} />
      <AboutCourseSection {...aboutCourseArgs} />
      <WhatPeopleSaySection
        reviews={whoWeHelpedTestimonials.nodes}
      />
      <WhoThisIsForSection {...whoThisIsForArgs} />
      <ModulesSection
        modules={modules}
        modulesTitle={modulesTitle.header}
      />
      <ScheduleSection {...scheduleTexts} />
      <MeetInstructorSection {...meetYourInstructorArgs} />
      <FAQSection
        questionsData={faq.nodes}
        title={faqTitle.header}
      />
      <ConnectSection />
    </>
  )
}

export default SalesMastery

export const SalesMasteryPageData = graphql`
  query {
    bannerText: contentfulSalesMasteryPage {
      header: block1BannerHeader
      subHeader: block1BannerSubHeader
    }
    navItems: contentfulSalesMasteryPage {
      items: block2NavigationList {
        childrenMarkdownRemark {
          htmlAst
        }
      }
    }
    courseOverview: contentfulSalesMasteryPage {
      aboutTitle: block3AboutTitle
      aboutDescription: block3AboutDescription {
        description: block3AboutDescription
      }
      whatIsIncludedTitle: block3WhatIsIncludedTitle
      whatIsIncludedList: block3WhatIsIncludedList {
        childrenMarkdownRemark {
          htmlAst
        }
      }
      whatYouWillLearnTitle: block3WhatYouWillLearnTitle
      whatYouWillLearnList: block3WhatYouWillLearnList {
        childrenMarkdownRemark {
          htmlAst
        }
      }
    }
    whoThisIsFor: contentfulSalesMasteryPage {
      title: block4WhoThisIsForTitle
      list: block4WhoThisIsForList {
        childrenMarkdownRemark {
          htmlAst
        }
      }
    }
    scheduleTexts: contentfulSalesMasteryPage {
      title: block5InterestedTitle
      description: block5InterestedDescription
    }
    meetYourInstructor: contentfulSalesMasteryPage {
      title: block6MeetInstructorTitle
      description: block6MeetInstructorDescription {
        description: block6MeetInstructorDescription
      }
    }
    faq: allContentfulSalesMasteryFaqQuestion {
      nodes {
        question
        answer {
          answer
        }
      }
    }
    faqTitle: contentfulSalesMasteryPage {
      header: block7FaqTitle
    }
    modulesData: allContentfulSalesMasteryModule {
      nodes {
        index: moduleIndex
        title: moduleTitle
        description: moduleDescription
        moduleImage {
          gatsbyImage(
            width: 450
            quality: 85
            placeholder: BLURRED
          )
        }
        fullDataTitle: moduleFullDataTitle
        fullDataLabel: moduleFullDataLabel
        fullDataDescription: moduleFullDataDescription {
          description: moduleFullDataDescription
        }
      }
    }
    modulesTitle: contentfulSalesMasteryPage {
      header: block5ModulesTitle
    }
    whoWeHelpedTestimonials: allContentfulSalesMasteryTestemonial {
      nodes {
        fullName
        designation
        review {
          review
        }
        profilePicture {
          gatsbyImage(width: 450, quality: 85)
        }
        embeddedVideo {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
