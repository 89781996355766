export type MainRoutesType = (
  | {
      label: string
      path: string
      expandable?: undefined
      children?: undefined
    }
  | {
      label: string
      path: string
      expandable: boolean
      children: {
        label: string
        path: string
      }[]
    }
  | {
      label: string
      expandable: boolean
      children: {
        label: string
        path: string
      }[]
      path?: undefined
    }
)[]

export interface MainRoutesProps {
  MainRoutes: MainRoutesType
  onTabChange: any
  onClose: any
  activeTab: string
  animate: any
}

export enum CountryCodes {
  TR = 'TR',
  GE = 'GE',
}
